$primary: #418bca;
$secondary: #a9b6bc;
$success: #00bc8c;
$info: #67c5df;
$warning: #f89a14;
$danger: #ef6f6c;
$light: #f8f9fa;
$dark: #343a40;
$purple: #7c3b97;
$pink: #e16e92;
$default: #f5f5f5;
